module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Can I Teach Myself Kiteboarding","short_name":"Teach Myself","start_url":"/","background_color":"#1d828a","theme_color":"#1d828a","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c73d896954e5169a11826557ad282345"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-8HSJ21VBKM"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
