// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-client-pages-dashboard-dashboard-js": () => import("./../../../src/pages/accountClientPages/Dashboard/Dashboard.js" /* webpackChunkName: "component---src-pages-account-client-pages-dashboard-dashboard-js" */),
  "component---src-pages-account-client-pages-login-login-js": () => import("./../../../src/pages/accountClientPages/Login/Login.js" /* webpackChunkName: "component---src-pages-account-client-pages-login-login-js" */),
  "component---src-pages-account-client-pages-password-reset-password-reset-js": () => import("./../../../src/pages/accountClientPages/PasswordReset/PasswordReset.js" /* webpackChunkName: "component---src-pages-account-client-pages-password-reset-password-reset-js" */),
  "component---src-pages-account-client-pages-signup-signup-js": () => import("./../../../src/pages/accountClientPages/Signup/Signup.js" /* webpackChunkName: "component---src-pages-account-client-pages-signup-signup-js" */),
  "component---src-pages-account-client-pages-verify-account-verify-account-js": () => import("./../../../src/pages/accountClientPages/VerifyAccount/VerifyAccount.js" /* webpackChunkName: "component---src-pages-account-client-pages-verify-account-verify-account-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

